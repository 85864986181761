<script setup>
    const props = defineProps({
        href: {
            type: String,
            default: null,
        },
        variant: {
            type: String,
            default: 'brand1',
        },
        solid: {
            type: Boolean,
            default: true,
        },
        padded: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: null,
        },
        iconMargin: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        hover: {
            type: Boolean,
            default: true,
        },
    })

    const tag = ref(props.href ? 'a' : 'button')
    const foreground = ref('white')
    const background = ref(props.variant)
    const isLoading = toRef(props, 'loading')

    const classes = () => {
        const classes = [
            'relative',
            'flex',
            'justify-center',
            'items-center',
            'gap-x-1',
            'font-bold',
            'border',
            'rounded-full',
            'text-lg',
            'leading-4',
            'py-2',
            'transition',
            `border-${props.variant}`,
        ]

        if (props.icon) {
            classes.push(`icon-${props.icon}`)

            if (props.iconMargin) {
                classes.push('after:ml-[.6em]')
            }
        }

        if (props.padded) {
            classes.push('px-12')
        } else {
            classes.push('px-6')
        }

        if (!props.solid) {
            foreground.value = props.variant
            background.value = 'white'
        }

        classes.push(`bg-${background.value}`)
        classes.push(`text-${foreground.value}`)

        if (props.icon?.includes('arrow')) {
            if (props.icon == 'arrow-right') {
                classes.push('after:ml-[.2em]')
                classes.push('after:h-3')
                classes.push('hover:after:translate-x-1')
            } else {
                classes.push('after:h-4')
            }
        } else if (isLoading.value || !props.hover) {
            classes.push('cursor-default')
        } else {
            classes.push(`hover:bg-${foreground.value}`)
            classes.push(`hover:text-${background.value}`)
        }

        if (props.loading) {
            classes.push('hide-icon')
        }

        return classes
    }
</script>

<template>
    <component :href="href" :is="tag" :class="classes().join(' ')">
        <TransitionGroup>
            <div key="slot" class="transition" :class="{ 'opacity-0': isLoading }"><slot /></div>

            <LoadingAnimation key="loading" v-if="isLoading" class="transition" />
        </TransitionGroup>
    </component>
</template>

<style scoped>
    a,
    button {
        --foreground: v-bind(foreground);
        --background: v-bind(background);

        &:after {
            @apply aspect-square;
            @apply transition;
            mask: var(--icon) no-repeat center / contain;
            background-color: currentColor;
        }

        &.hide-icon:after {
            opacity: 0;
        }
    }
</style>
