<script setup>
    useHead({
        titleTemplate: (titleChunk) => {
            return titleChunk ? `${titleChunk} • YouNovate` : 'YouNovate'
        },
    })
</script>

<template>
    <SiteHeader />

    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>

    <SiteFooter />
</template>

<style>
    @tailwind base;
    @tailwind components;
    @tailwind utilities;

    @layer base {
        :root {
            --slider-bg: transparent;
            --slider-connect-bg: transparent;
            --slider-handle-border: #254eff solid;
            --slider-handle-width: 22px;
            --slider-handle-height: 22px;
            --slider-height: 10px;
            --slider-handle-shadow: none;

            --ms-radius: 0;
            --ms-border-color: #0a0430;
            --ms-option-bg-selected: #254eff;
            --ms-option-bg-selected-pointed: #254eff;
            --ms-ring-color: #254eff20;
            --ms-option-color-selected: white;
            --ms-px: 0;
        }

        html {
            font-size: 20px;

            @media (max-width: 1280px) {
                font-size: 18px;
            }

            @media (max-width: 1024px) {
                font-size: 16px;
            }
        }

        body {
            @apply text-dark;
            font-family: 'Open Sans', sans-serif;
        }

        #__nuxt {
            display: flex;
            flex-direction: column;
            min-height: calc(100dvh);

            > header + div {
                padding-top: var(--header-height);
            }
        }

        h1,
        h2 {
            @apply font-bold;

            em {
                @apply not-italic text-brand1;
            }
        }

        h1 {
            @apply text-2xl;
        }

        h2 {
            @apply text-xl;
        }

        .slider-target {
            margin-inline: 11px;
        }
    }

    @layer components {
        .icon:after {
            content: '';
            @apply block;
            background-color: currentColor;
            mask: var(--icon) no-repeat center / contain;
        }

        .icon-menu {
            --icon: url('@/assets/icons/menu.svg');

            &:after {
                mask: var(--icon) no-repeat center / contain;
            }
        }

        :root {
            --padding: 15px;

            @screen md {
                --padding: 1rem;
            }

            @screen lg {
                --padding: 2rem;
            }
        }

        .container {
            padding-inline: var(--padding);
        }

        .slider-base,
        .slider-connect {
            &:before {
                content: '';
                position: absolute;
                inset-inline: 0;
                top: 50%;
                height: 1px;
                background-color: #0a0430;
            }
        }

        .slider-connect:before {
            background-color: #254eff;
        }
    }

    @layer utilities {
        .centre-absolute {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .dotted {
            @apply relative;
            padding-left: 12px;

            &:before {
                content: '';
                position: absolute;
                top: 0.5em;
                left: 0;
                transform: translateY(-50%);
                width: 7px;
                height: 7px;
                @apply bg-brand1;
                @apply rounded-full;
            }
        }

        .hover-underline {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                inset-inline: 3px;
                bottom: 3px;
                height: 1px;
                background-color: currentColor;
                opacity: 0;
                @apply transition-all;
            }

            &:hover:after {
                inset-inline: 0;
                bottom: 0;
                opacity: 1;
            }
        }

        :root {
            --spacing-medium: 2rem;
            --spacing-large: 3rem;

            @screen sm {
                --spacing-medium: 3rem;
                --spacing-large: 4rem;
            }

            @screen md {
                --spacing-medium: 4rem;
                --spacing-large: 6rem;
            }

            @screen lg {
                --spacing-medium: 5rem;
                --spacing-large: 7rem;
            }

            @screen xl {
                --spacing-medium: 6rem;
                --spacing-large: 8rem;
            }
        }

        .pt-lg {
            padding-top: var(--spacing-large);
        }

        .mt-lg {
            margin-top: var(--spacing-large);
        }

        .-mb-lg {
            margin-bottom: calc(var(--spacing-large) * -1);
        }

        .mt-md {
            margin-top: var(--spacing-medium);
        }

        .py-md {
            padding-top: var(--spacing-medium);
            padding-bottom: var(--spacing-medium);
        }
    }

    :root {
        --brand1: #254eff;
        --brand2: #ed5257;
        --white: #ffffff;
        --dark: #0a0430;
        --dark-alt: #2f2f2d;
        --mid: #828282;
        --light: #f2f2f2;
        --current: currentColor;
        --transparent: transparent;
    }

    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }

    .icon-arrow-right {
        --icon: url('@/assets/icons/arrow-right.svg');
    }

    .icon-arrow-top-right {
        --icon: url('@/assets/icons/arrow-top-right.svg');
    }

    .icon-plus {
        --icon: url('@/assets/icons/plus.svg');
    }

    .icon-arrow-down {
        --icon: url('@/assets/icons/arrow-down.svg');
    }
</style>
