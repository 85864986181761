<script setup>
    const props = defineProps({
        handle: String,
    })

    const nav = await useStatamic().getNavTree(props.handle)
</script>

<template>
    <nav class="flex gap-6">
        <NuxtLink
            v-for="item in nav"
            :key="item.page.slug"
            :href="item.page.uri"
            class="hover-underline relative"
        >
            {{ item.page.title }}
        </NuxtLink>
    </nav>
</template>
