<script setup>
    const site = await useStatamic().getGlobal('site')
    const company = await useStatamic().getGlobal('company')

    const copyrightText = () => site.copyright_text.replace('{{ current_year }}', new Date().getFullYear())
</script>

<template>
    <footer class="xl:lg-20 mt-auto bg-brand1 py-8 text-center text-white sm:py-12 lg:py-16">
        <div class="container">
            <p class="text-center">
                <img :src="company?.logo_alt.permalink" class="relative mx-auto" width="265" height="36" />
                <a href="https://streetfleet.com.au/" class="hover-underline text-xs">
                    Powered by StreetFleet
                </a>
                <img
                    src="@/assets/images/ao-certified-logo.png"
                    class="relative mx-auto mt-4 max-w-[100px]"
                />
            </p>

            <NavMenu handle="footer" class="mx-auto mt-8 inline-flex text-xs md:mt-5" />
            <p class="mt-2 text-xs">{{ copyrightText() }}</p>
        </div>
    </footer>
</template>
