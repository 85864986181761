export const useStatamic = () => {
    const apiUrl = useRuntimeConfig().public.statamicUrl + '/api'

    const getGlobal = (globalHandle, options = {}) => apiGet(`globals/${globalHandle}`, options)

    const getNavTree = (navHandle, options = {}) => apiGet(`navs/${navHandle}/tree`, options)

    const getEntries = (collection, options = {}) => apiGet(`collections/${collection}/entries`, options)

    const getEntry = (collection, slug, options = {}) => {
        if (!slug) {
            slug = 'home'
        }

        const endpoint = `collections/${collection}/entries?filter[slug:equals]=${slug}`

        return apiGet(endpoint, options).then((data) => {
            if (typeof data === 'undefined') {
                console.error('getEntry', `No entry found for ${slug}`)
                return null
            }

            return data[0]
        })
    }

    const getForm = (handle, options = {}) => apiGet(`forms/${handle}`, options)

    const addSubmission = (handle, data) => apiPost(`forms/${handle}/submit`, data)

    const apiGet = (url, options = {}) => {
        console.log('apiGet', `${apiUrl}/${url}`)

        if (!options.transform) {
            options.transform = (data) => {
                if (typeof data === 'object') {
                    return removeCruft(data)
                }

                if (typeof data === 'array') {
                    return data.map((item) => removeCruft(item))
                }

                return data
            }
        }

        return useFetch(`${apiUrl}/${url}`, options)
            .then(({ data, error }) => {
                if (error.value) {
                    console.log(error.value)

                    throw createError({
                        statusCode: 500,
                        statusMessage: 'API Error (code 77)',
                    })
                }

                return data.value.data
            })
            .catch((error) => {
                console.log(error)

                throw createError({
                    statusCode: 500,
                    statusMessage: 'API Error (code 66)',
                })
            })
    }

    const removeCruft = (item) => {
        const keysToDelete = [
            'api_url',
            'blueprint',
            'collection',
            'edit_url',
            'mount',
            'order',
            'locale',
            'updated_at',
            'updated_by',
            'private',
            'published',
            'status',
        ]

        keysToDelete.forEach((key) => {
            delete item[key]
        })

        return item
    }

    const apiPost = (url, params) =>
        $fetch(`${apiUrl}/${url}`, {
            method: 'POST',
            body: JSON.stringify(params),
        })

    return {
        getGlobal,
        getNavTree,
        getEntries,
        getEntry,
        getForm,
        addSubmission,
        apiPost,
    }
}
