import { default as _91_91slug_93_93bmiP6XFFEXMeta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/[[slug]].vue?macro=true";
import { default as enquireVrFLJHBtDPMeta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/enquire.vue?macro=true";
import { default as faqtINBZoEeFJMeta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/faq.vue?macro=true";
import { default as indexRnD4wjZ73BMeta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/index.vue?macro=true";
import { default as quote3K8edILnoBMeta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/quote.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug?",
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "enquire",
    path: "/enquire",
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/enquire.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexRnD4wjZ73BMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "quote",
    path: "/quote",
    meta: quote3K8edILnoBMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/quote.vue").then(m => m.default || m)
  }
]