<script setup>
    const company = await useStatamic().getGlobal('company')
    const menuOpen = ref(false)
</script>

<template>
    <header
        :class="{ mv: menuOpen, 'bg-brand1': menuOpen, 'bg-white': !menuOpen }"
        class="site-header group fixed inset-x-0 top-0 z-50 flex items-center justify-between gap-4 py-2 font-bold shadow transition"
    >
        <div class="lg:shrink-1 h-full md:py-1">
            <NuxtLink href="/" class="logo block h-full" :style="`--src: url(${company.logo.permalink})`">
                <img
                    :src="company.logo.permalink"
                    class="mh-full h-full transition"
                    :class="{ 'opacity-0': menuOpen }"
                />
            </NuxtLink>
        </div>

        <a
            href="https://streetfleet.com.au/"
            target="_blank"
            class="hover-underline ml-auto mr-1 text-[9px] font-light text-brand1 transition min-[480px]:mr-3 min-[480px]:text-xs md:mr-1"
            :class="{ 'text-white': menuOpen }"
        >
            Powered&nbsp;by&nbsp;StreetFleet
        </a>

        <div
            class="mm-wrapper pointer-events-none fixed inset-0 top-[40px] z-40 flex flex-col items-center gap-4 bg-brand1 py-6 opacity-0 transition group-[.mv]:pointer-events-auto group-[.mv]:opacity-100 lg:pointer-events-auto lg:static lg:shrink-0 lg:flex-row lg:bg-white lg:py-0 lg:opacity-100"
        >
            <PrettyButton
                :href="`tel:${company.phone}`"
                :solid="menuOpen"
                :variant="menuOpen ? 'white' : 'brand1'"
                class="order-last w-full lg:order-first lg:ml-auto lg:w-auto lg:hover:bg-brand1 lg:hover:text-white"
            >
                {{ company.phone }}
            </PrettyButton>

            <NavMenu
                handle="top"
                class="my-auto flex-col text-center text-xl text-white lg:my-0 lg:ml-3 lg:flex-row lg:text-base lg:text-dark"
            />
        </div>

        <button
            @click="menuOpen = !menuOpen"
            class="icon-menu block h-[21px] w-[25px] after:block after:h-full after:bg-brand1 group-[.mv]:after:!bg-white lg:hidden"
        ></button>
    </header>
</template>

<style>
    :root {
        --header-height: 40px;

        @screen sm {
            --header-height: 46px;
        }

        @screen md {
            --header-height: 59px;
        }

        @screen lg {
            --header-height: 84px;
        }
    }

    header.site-header {
        height: var(--header-height);
        padding-inline: var(--padding);

        .logo {
            mask-image: var(--src);
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
            background-color: white;
        }

        .mm-wrapper {
            padding-inline: var(--padding);

            @screen sm {
                padding-inline: 0;
            }
        }
    }
</style>
